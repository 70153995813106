import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconArrowRight = ({
  title = 'ArrowRight',
  color = 'dark',
  viewBoxWidth = 27,
  viewBoxHeight = 49,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path d="M1 0l25 25.094L2.184 49" stroke={colors[color]} strokeWidth="8" fill="none" fillRule="evenodd" />
  </SvgIcon>
);

export default IconArrowRight;
