import compact from 'lodash-es/compact';
import { Taxon } from '../contexts/AppContext/types';

export const flattenTaxons = (taxons: Taxon[]): Taxon[] =>
  taxons.reduce((acc, x) => {
    const flattened = [...acc, x];
    if (x.taxons) {
      return [...flattened, ...flattenTaxons(x.taxons)];
    }
    return flattened;
  }, []);

export const findTaxon = (taxons: Taxon[], taxonName: string): Taxon => {
  const foundNodes: Taxon[] = taxons.reduce((acc, t) => {
    let nodes;
    if (t.name === taxonName) {
      nodes = [...acc, t];
    } else {
      nodes = [...acc, t.taxons.length > 0 ? findTaxon(t.taxons, taxonName) : null];
    }
    return nodes;
  }, []);
  return compact(foundNodes)[0];
};

export const findTaxonBySlug = (taxons: Taxon[], taxonSlug: string, prefix: string | null = null): Taxon => {
  let searchSlug = taxonSlug;
  if (prefix) {
    searchSlug = prefix + searchSlug;
  }
  const foundNodes: Taxon[] = taxons.reduce((acc, t) => {
    let nodes;
    if (t.permalink === searchSlug) {
      nodes = [...acc, t];
    } else {
      nodes = [...acc, t.taxons.length > 0 ? findTaxonBySlug(t.taxons, taxonSlug, prefix) : null];
    }
    return nodes;
  }, []);
  return compact(foundNodes)[0];
};
