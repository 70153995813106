import AppContext from 'app/contexts/AppContext/AppContext';
import { CmsCategoryPage, CmsProductPage, CmsServicePage, Product } from 'app/contexts/AppContext/types';
import PersephoneContext from 'app/contexts/PersephoneContextContainer/PersephoneContext';
import { filter } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useCms from 'utils/hooks/useCms';
import { getProductSlugs } from '../utils/utils';
import useQuoteParams from './useQuoteParams';

const getProduct = (products: Product[], slug: string) => products.find(elem => elem.slug === slug);

const getPage = (cmsPages: (CmsProductPage | CmsCategoryPage | CmsServicePage)[], path: string) => {
  let page = cmsPages.find(elem => elem.fullPath === path) as CmsProductPage | undefined;

  if (
    page &&
    (page.description === null || page.description === undefined || page.description === '') &&
    page.fullPath.includes('/landing/')
  ) {
    const basePath = path.split('/').pop();
    page = cmsPages.find(elem => elem.fullPath.includes(basePath) && !elem.fullPath.includes('/landing/')) as
      | CmsProductPage
      | undefined;
  }

  return page;
};

const getProducts = (products: Product[], cmsPage: CmsProductPage, productSlug: string) =>
  filter<Product>(products, pr =>
    cmsPage.productTiles
      ? cmsPage.productTiles.productTiles.map(e => e.slug).includes(pr.slug)
      : pr.slug === productSlug,
  );

const getCategoryCms = (
  cmsPages: (CmsProductPage | CmsCategoryPage | CmsServicePage)[],
  selectedProductCms: CmsProductPage,
) =>
  cmsPages.find(elem => elem.fullPath === `/${selectedProductCms.fullPath.split('/')[1]}`) as CmsCategoryPage;

const useSelected = () => {
  const persephoneContext = useContext(PersephoneContext);
  const appContext = useContext(AppContext);
  const cms = useCms<CmsProductPage>();
  const { values: quoteValues } = useQuoteParams();
  const storeProducts = appContext.store.products;
  const storePages = appContext.store.cmsPages;

  const { rootSlug, productSlug } = useMemo(
    () =>
      getProductSlugs({
        cmsPage: cms,
        quoteValues,
        checkSlugFn: slug => storeProducts.find(elem => elem.slug === slug) !== undefined,
      }),
    [cms?.id, persephoneContext.catalog],
  );

  const [firstRender, setFirstRender] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<Product>(getProduct(storeProducts, productSlug));
  const [products, setProducts] = useState<Product[]>(getProducts(storeProducts, cms, productSlug));

  const handleSelectProduct = useCallback(
    (slug: string) => {
      const newSelectedProduct = getProduct(products, slug);
      setSelectedProduct(newSelectedProduct);
    },
    [products],
  );

  useEffect(() => {
    // avoid changing products twice on first render
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    const newProducts = getProducts(storeProducts, cms, productSlug);
    const newSelectedProduct = getProduct(newProducts, productSlug);

    setProducts(newProducts);
    setSelectedProduct(newSelectedProduct);
  }, [rootSlug, productSlug, cms?.id]);

  const selectedProductCms = useMemo(() => getPage(storePages, rootSlug), [rootSlug]);
  const selectedCategoryCms = useMemo(
    () => getCategoryCms(storePages, selectedProductCms),
    [selectedProductCms.id],
  );

  return {
    selectedProduct,
    selectedProductCms,
    selectedCategoryCms,
    products,
    selectProduct: handleSelectProduct,
    rootSlug,
    productSlug,
  };
};

export default useSelected;
