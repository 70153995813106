import * as React from 'react';
import { IconArrowUp } from 'styleguide/icons';

const BackToTop = () => (
  <div
    className="caption z-[10] fixed bottom-32 right-0 flex h-16 w-14 cursor-pointer flex-col
   justify-center rounded-l-full bg-blue text-center text-white"
    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    onKeyDown={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    role="button"
    tabIndex={0}
  >
    <IconArrowUp color="white" className="mb-1 !h-4 !w-3 self-center" />
    Back to top
  </div>
);
export default BackToTop;
