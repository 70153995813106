import * as React from 'react';

import SvgIcon, { SvgProps } from 'styleguide/components/Svg/SvgIcon';
import colors from 'styleguide/styles/colors';

const IconArrowLeft = ({
  title = 'ArrowLeft',
  color = 'dark',
  viewBoxWidth = 27,
  viewBoxHeight = 49,
  ...otherProps
}: SvgProps) => (
  <SvgIcon {...{ title, viewBoxWidth, viewBoxHeight, color, ...otherProps }}>
    <path
      d="M26 0L1 25.094 24.816 49"
      stroke={colors[color]}
      strokeWidth="8"
      fill="none"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export default IconArrowLeft;
