import loadable from '@loadable/component';
import retry from 'utils/retry';

export const Reviews = loadable(() => retry(() => import(/* webpackPrefetch: true */ './Reviews/Reviews')));
export const RelatedItems = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ 'styleguide/components/RelatedItems/RelatedItems')),
);
export const InnerNavigation = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './InnerNavigation/InnerNavigation')),
);
export const CmsContentSection = loadable(() =>
  retry(
    () =>
      import(
        /* webpackPrefetch: true */ 'bundles/App/pages/ProductDetailsPage/CmsContentSection/CmsContentSection'
      ),
  ),
);
export const AdditionalInfo = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './AdditionalInfo/AdditionalInfo')),
);
export const FAQ = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './AdditionalInfo/FAQ/FAQ')),
);
export const Articles = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './AdditionalInfo/Articles/Articles')),
);
export const TemplateSpecs = loadable(() =>
  retry(() => import(/* webpackPrefetch: true */ './AdditionalInfo/TemplateSpecs/TemplateSpecs')),
);
