import { CmsProductPage } from 'app/contexts/AppContext/types';

const isValidSlug = (slugValue: string, checkFn: (slug: string) => boolean) =>
  slugValue && checkFn(slugValue);

export const getProductSlugs = ({
  cmsPage,
  quoteValues,
  checkSlugFn,
}: {
  cmsPage: CmsProductPage;
  quoteValues: { product?: string };
  checkSlugFn: (slug: string) => boolean;
}) => {
  let productSlug;

  if (isValidSlug(quoteValues.product, checkSlugFn)) {
    productSlug = quoteValues.product;
  } else if (cmsPage.productTiles && isValidSlug(cmsPage.productTiles.productTiles[0].slug, checkSlugFn)) {
    productSlug = cmsPage.productTiles.productTiles[0].slug;
  } else {
    productSlug = cmsPage.fullPath.split('/').slice(-1)[0];
  }

  return {
    rootSlug: cmsPage.fullPath,
    productSlug,
  };
};
