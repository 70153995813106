import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { CmsProductPage, Product } from 'contexts/AppContext/types';
import QuoteContext from 'contexts/QuoteContext/QuoteContext';

interface Props {
  product: Product;
  productPage?: CmsProductPage;
}

const createProductJson = (price: number, product: Product, productPage: CmsProductPage): string =>
  JSON.stringify({
    '@context': `https://schema.org`,
    '@type': 'Product',
    image: product.image?.url,
    name: product.name,
    description: productPage.merchantProductInfo.merchantDescription,
    category: productPage.merchantProductInfo.merchantCategory,
    mpn: productPage.merchantProductInfo.merchantMpn,
    sku: product.slug,
    brand: 'Printivity',
    offers: {
      '@type': 'Offer',
      price,
      priceCurrency: 'USD',
      url: productPage.metaData.metaCanonicalLink,
      itemCondition: 'NewCondition',
      availability: 'InStock',
    },
  });

const ProductJsonLd = ({ product, productPage }: Props) => {
  const quoteContext = React.useContext(QuoteContext);
  const [init, setInit] = React.useState(false);
  const [price, setPrice] = React.useState(null);

  React.useEffect(() => {
    if (quoteContext && quoteContext.price && !init) {
      setPrice(quoteContext.price);
      setInit(true);
    }
  }, [quoteContext.price]);

  return (
    <Helmet>
      {!!price && (
        <script type="application/ld+json">{createProductJson(price, product, productPage)}</script>
      )}
    </Helmet>
  );
};

export default ProductJsonLd;
