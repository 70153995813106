import * as React from 'react';
import Link from 'styleguide/components/Links/Link';
import { clickEmailUs } from 'api/gtm';

interface Props {
  emailUsUrl: string;
  phone: string;
}

const CallEmailUs = ({ phone, emailUsUrl }: Props) => (
  <div className="text-xs leading-tight lg:text-sm">
    <div className="text-gray-400">{`Call ${phone}`}</div>
    <Link
      to={emailUsUrl}
      className="!text-xs !leading-tight lg:!text-sm"
      color="blue"
      underline="always"
      onClick={() => clickEmailUs({ location: 'pdp_sticky_nav' })}
    >
      Email Us
    </Link>
  </div>
);

export default CallEmailUs;
