import { useLocation } from 'react-router-dom';
import queryString from 'qs';
import { KeyVal } from 'app/libs/utils/common-types';
import { useMemo } from 'react';

const parseQuote = (values: KeyVal) =>
  Object.keys(values).reduce((acc, key) => {
    const keyWithoutQuestionMark = key.replace('?', '');
    if (keyWithoutQuestionMark.startsWith('quote_')) {
      acc[keyWithoutQuestionMark.replace('quote_', '')] = values[key];
    } else if (keyWithoutQuestionMark === 'product') {
      acc[keyWithoutQuestionMark] = values[key];
    }
    return acc;
  }, {} as KeyVal);

const useQuoteParams = () => {
  const location = useLocation();

  const memoizedValues = useMemo(() => {
    const values = parseQuote(queryString.parse(location.search) as KeyVal);
    const valuesNotEmpty =
      values && Object.keys(values).length > 0 && !(Object.keys(values).length === 1 && values.product);

    return { values, valuesNotEmpty };
  }, [location.search]);

  return memoizedValues;
};

export default useQuoteParams;
