import * as React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { servicePath } from 'bundles/App/routes';

const WorriedAboutDesign = () => (
  <div className="ml-1 flex w-[42%] flex-col md:ml-2">
    <span className="mb-1 font-hvBold md:!text-sm lg:!text-base">
      Worried about design issues delaying your print job?
    </span>

    <div data-cy="worriedAboutDesignText" className="text-xs hidden md:block">
      We can help fix file issues, update your designs, or even create something new! Get in touch with a{' '}
      <Link
        data-cy="worriedAboutDesignLink"
        className="!text-xs"
        to={`${servicePath.designServices}#design-services-form`}
        underline="always"
        smooth
      >
        designer
      </Link>{' '}
      today!
    </div>
  </div>
);

export default WorriedAboutDesign;
