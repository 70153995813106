import * as React from 'react';
import BlockWithIcon from '../BlockWithIcon/BlockWithIcon';
import CallEmailUs from '../CallEmailUs/CallEmailUs';
import { IconPhone } from 'styleguide/icons';

interface Props {
  contactPhone: string;
  emailUsUrl: string;
}

const NeedAssistance = ({ contactPhone, emailUsUrl }: Props) => (
  <BlockWithIcon
    className="md:!text-sm lg:!text-base"
    title="Need assistance?"
    icon={<IconPhone color="darkGray" className="!w-3.5 md:!text-xs" />}
  >
    <CallEmailUs phone={contactPhone} emailUsUrl={emailUsUrl} />
  </BlockWithIcon>
);

export default NeedAssistance;
