import * as React from 'react';
import cn from 'classnames';

interface Props {
  icon: React.ReactNode;
  title: string;
  children?: React.ReactNode;
  className?: string;
}

const BlockWithIcon = ({ title, icon, ...props }: Props) => (
  <div className={cn('flex flex-row space-x-2', props.className)}>
    <div className="self-start">{icon}</div>
    <div>
      <span className="font-hvBold">{title}</span>
      {props.children}
    </div>
  </div>
);

export default BlockWithIcon;
