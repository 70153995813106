import * as React from 'react';
import cn from 'classnames';
import css from './ProductSelector.scss';
import Grid from 'styleguide/components/Grid/Grid';
import Image from 'styleguide/components/Image/Image';
import Label from 'styleguide/components/forms/Label/Label';
import { Product } from 'contexts/AppContext/types';

interface Props {
  products: Product[];
  selectedProduct: Product;
  productSelectionHandler: (productName: string, e: React.MouseEvent<HTMLElement>) => void;
}

const ProductCard = ({ image, name, slug, isSelected, productSelectionHandler }) => (
  <Grid.Col className={cn(css.productSelectorCard, isSelected && css.selectedProductSelectorCard)} sm={3}>
    <div
      onClick={e => productSelectionHandler(slug, e)}
      onKeyDown={e => productSelectionHandler(slug, e)}
      role="button"
      tabIndex={0}
    >
      {image && <Image alt={image.alt} url={image.url} className={css.productImage} />}
      <p className={cn(css.productName, isSelected && css.selectedProductSelectorLabel)}>{name}</p>
    </div>
  </Grid.Col>
);
const ProductSelector = ({ products, ...props }: Props) => (
  <Grid.Container className="px-4 md:px-0">
    <Grid noPadding>
      <Grid.Row className="mb-2 justify-center pl-3 md:!justify-start">
        <Label className={css.productSelectorLabel} placement="top">
          Select a binding
        </Label>
      </Grid.Row>
      <Grid.Row className="mb-4 justify-center px-2 md:!justify-start">
        {
          // Todo: Added this i<4 Condition in filter to ensure not more than 4 Selection Card Appears as styling for more than that isn't discussed
        }
        {products
          .filter((el: Product, i: number) => i < 4)
          .map((item: Product, i: number) => (
            <ProductCard
              key={`product-selection-card-${i}`}
              name={item.name}
              image={item.image}
              isSelected={props.selectedProduct.slug === item.slug}
              slug={item.slug}
              productSelectionHandler={props.productSelectionHandler}
              // ToDo: add Description Text
              // descriptionText={Text}
            />
          ))}
      </Grid.Row>
    </Grid>
  </Grid.Container>
);

export default ProductSelector;
