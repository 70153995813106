import * as React from 'react';
import BlockWithIcon from '../BlockWithIcon/BlockWithIcon';
import { IconShipping } from 'styleguide/icons';
import { getDurationString, weekdayAndDate } from 'utils/time';

interface Props {
  hidden?: boolean;
  date: string;
  durationSeconds: number;
}

const RushDeliveryAvailable = ({ date, durationSeconds, ...props }: Props) =>
  !props.hidden ? (
    <BlockWithIcon
      title="Rush delivery available:"
      icon={<IconShipping color="darkGray" size="xl" className="relative !h-[20px] !w-[20px]" />}
      className="md:!text-sm lg:!text-base "
    >
      <div className="text-xs leading-tight text-gray-400 lg:text-sm">
        <div className="text-xs leading-tight text-gray-400 lg:text-sm">
          Want it <span className="font-hvBold text-gray-400">{weekdayAndDate(date)}?</span>
        </div>
        <div className="text-xs leading-tight text-gray-400 lg:text-sm">
          Order within:{' '}
          <span className="font-hvBold text-orange-500">{getDurationString(durationSeconds)}</span>
        </div>
      </div>
    </BlockWithIcon>
  ) : null;

export default RushDeliveryAvailable;
